import { useBenefitPlans } from 'api/query-hooks'
import { useSurveyData } from 'feature/survey/hooks/use-survey-data'
import { captureFlagEvaluation, useTrackDetailedPageEvent } from 'hooks/analytics'
import { EnrollmentType, useEnrollmentData, useNewHirePlans } from 'hooks/user-data'
import { useEffectOnceSessionStorageCondition } from 'hooks/utils'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'
import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { camelToSnakeCase } from 'utils/str/camelToSnakeCase'

export const useTrackEligiblityEvaluations = () => {
	const { b4b_15094_post_login_landing, b4b_19026_experience_config } = useFlags()
	const track = useTrackDetailedPageEvent()
	const { enrollmentType, isAllDataLoaded } = useEnrollmentData()
	const { data: availablePlans, isFetching } = useBenefitPlans()
	const { currentlyEligibleToTakeSurvey } = useSurveyData()
	const {
		isFetched: isNewHireFetched,
		isNewHire,
		isProgramStart,
		rawNewHireEndDate,
		rawNewHireStartDate,
	} = useNewHirePlans()

	const postLoginRoutingBuckets = {
		'/home': 'control',
		'/wizard': 'wizard',
		'/wizard/intro': 'wizard intro',
	}

	const newHireReady = isNewHire ? isNewHireFetched : true

	const shouldSend: boolean = useMemo(
		() => !isFetching && newHireReady && isAllDataLoaded && !!availablePlans && availablePlans.length > 0,
		[availablePlans, isAllDataLoaded, isFetching, newHireReady],
	)

	useEffectOnceSessionStorageCondition(
		() => {
			send({
				enrollmentType,
				newHireEvent: {
					isNewHire,
					isProgramStart: isProgramStart ?? false,
					newHireEndDate: rawNewHireEndDate,
					newHireStartDate: rawNewHireStartDate,
				},
				plans: availablePlans ?? [],
			})
		},
		'enrollment-evaluation',
		shouldSend,
	)

	const send = ({ enrollmentType, newHireEvent, plans }: EligibilityEventData) => {
		// Enrollment Type
		// CK - we are doing this because we didn't want to do the lift to add the new type across the app
		const modifiedEnrollmentType = newHireEvent.isProgramStart ? 'program start' : enrollmentType

		track({
			actionType: 'user evaluation',
			elementDetails: modifiedEnrollmentType,
			elementName: 'enrollment type',
			elementType: 'eligibility',
			pageName: 'home',
		})

		// Eligible plans
		track({
			actionType: 'user evaluation',
			elementDetails: plans.map((plan) => plan.benefitPlanId).join(', '),
			elementName: 'benefit plan eligibility',
			elementType: 'eligibility',
			pageName: 'home',
		})

		track({
			actionType: 'user evaluation',
			elementDetails: currentlyEligibleToTakeSurvey ? 'true' : 'false',
			elementName: 'decision support enabled',
			elementType: 'eligibility',
			pageName: 'home',
		})

		// Wizard Intro
		track({
			actionType: 'user evaluation',
			elementDetails: postLoginRoutingBuckets[b4b_15094_post_login_landing] ?? 'control',
			elementName: 'wizard intro eligibility',
			elementType: 'eligibility',
		})

		// new hire
		track({
			actionType: 'user evaluation',
			// utilizing camelToSnakeCase, remap object keys to snake case and stringify
			elementDetails: JSON.stringify(
				Object.fromEntries(Object.entries(newHireEvent).map(([key, value]) => [camelToSnakeCase(key), value])),
			),
			elementName: 'new hire',
			elementType: 'eligibility',
			pageName: 'home',
		})

		captureFlagEvaluation('b4b_19026_experience_config', b4b_19026_experience_config)
	}
}

type EligibilityEventData = {
	enrollmentType: EnrollmentType | undefined
	newHireEvent: {
		isNewHire: boolean
		isProgramStart: boolean
		newHireEndDate: string | undefined
		newHireStartDate: string | undefined
	}
	plans: BenefitPlanV2[]
}
