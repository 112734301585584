import { benefitRoutes, routesMap } from 'routes/routes-map'
import { match } from 'ts-pattern'

type PostLoginRouteParams = {
	hasCompletedNewHire?: boolean
	hasCompletedOE?: boolean
	isNewHire: boolean
	isOE?: boolean
	isSingleProductOE: boolean
	landing: string
}

export function determinePostLoginRoute(params: PostLoginRouteParams): string {
	return (
		match(params)
			.with({ isSingleProductOE: true }, () => routesMap.home) // Single product OE overrides all other logic, go to home
			.with({ landing: '/home' }, () => routesMap.home)
			// wizard pdp as first page logic
			.with({ hasCompletedOE: false, isOE: true, landing: '/wizard' }, () => benefitRoutes.wizard.base)
			.with({ hasCompletedNewHire: false, isNewHire: true, landing: '/wizard' }, () => benefitRoutes.wizard.base)
			.with({ landing: '/wizard' }, () => routesMap.home)
			// wizard intro as first page logic
			.with({ hasCompletedOE: false, isOE: true, landing: '/wizard/intro' }, () => benefitRoutes.wizard.intro)
			.with({ hasCompletedNewHire: false, isNewHire: true, landing: '/wizard/intro' }, () => benefitRoutes.wizard.intro)
			.with({ landing: '/wizard/intro' }, () => routesMap.home)
			.otherwise(() => routesMap.home)
	)
}
