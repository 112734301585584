import { useFlags } from 'launchdarkly-react-client-sdk'
import { useCallback } from 'react'
import { trackEvent } from 'utils/utils'

/**
 * @deprecated use useCaptureEvent for new features
 */
export const useTrackEvent = () => {
	const { b4b_19185_disable_segment } = useFlags()

	const trackEventCallback = useCallback(
		(eventName: string, eventData?: Record<string, unknown>) => trackEvent(eventName, eventData),
		[],
	)

	if (b4b_19185_disable_segment) {
		return () => {}
	}

	return trackEventCallback
}
